import * as Sentry from '@sentry/gatsby';
import BuildInfo from './static/build.json'
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://a8eea015e2084032a58eeeea6be9c8a6@o191827.ingest.sentry.io/6269133",
    sampleRate: 1.0, // Percentage of errors to send
    tracesSampleRate: 0.2, // Percentage of performance traces to send
    integrations: [new BrowserTracing()],
    environment: BuildInfo.ctx,
    beforeSend(event) {

        // Modify the event here
        if (event.user) {
            // Don't send user's email address
            //delete event.user.email;
        }
        return event;
    }
});